.correlationMatrix {
	padding-top: 20px;

	.row {
		display: flex;
		flex-direction: row;
		margin: 0px;
		padding: 0px;

		.leftLabel {
			display: flex;
			flex-direction: column;
			text-align: left;
			justify-content: center;
			width: 100px;
			padding: 0px;
			margin: 0px;
			margin-left: 10px;
			font-size: 10px;
		}

		.coordinate {
			width: 20px;
			height: 20px;
			margin: 1px;
			padding: 5px;
			font-size: 10px;
			display: flex;
			flex-direction: column;
			text-align: center;
			justify-content: center;
			cursor: pointer;
			color: white;
		}

		.coordinate:hover {
			transform: scale(1.2);
		}
	}

	.bottomLabel {
		display: flex;
		flex-direction: row;
		margin-left: 120px;
		margin-bottom: 70px;
		p {
			width: 20px;
			transform: rotate(-45deg) translate(-190%);
			// transform: rotate(-45deg) translate(-190%);
			margin: 0;
			padding: 5px;
			margin: 1px;
			font-size: 9px;
		}
	}
}
