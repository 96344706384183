@font-face {
	font-family: "Epilogue";
	src: url("../shared/assets/fonts/Epilogue/Epilogue-VariableFont_wght.ttf");
}

@font-face {
	font-family: "Libre Baskerville";
	src: url("../shared/assets/fonts/LibreBaskerville/LibreBaskerville-Regular.ttf");
}

@font-face {
	font-family: "Fira Sans";
	src: url("../shared/assets/fonts/FiraSans/FiraSans-ExtraLight.ttf");
}

@font-face {
	font-family: "Roboto";
	src: url("../shared/assets/fonts/Roboto/Roboto-Light.ttf");
}

* {
	font-family: "Roboto";
}
