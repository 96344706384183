.mapboxLegendContainer {
	width: 920px;
	position: absolute;
	z-index: 4;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.routeSelector {
		margin: 5px 0 0 10px;
		padding: 1px;
		width: 20%;
	}

	.routeDetails {
		font-weight: bolder;
		text-align: right;
		width: 30%;
		margin: 5px 0 0 10px;
		padding: 1px;
		line-height: 1.5;
	}
}
