#kCard {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

a {
  text-decoration: none;
}

.iframe-container {
  height: 100%;
  width: 100%;
  &__data {
    &__iframe {
      // display: inline-flex;
      // flex-direction: column;
    }
    &__title {
      margin-top: 0;
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.iframe-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	// justify-content: space-evenly;
}

.iframe-col {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.card-container {
  &__title {
    & > p {
      font-size: 22px;
      font-weight: 600;
    }
  }
}

.dashboard-title {
  margin-left: 28px;
  font-weight: 600;
}

.iframe-container__list {
  margin-top: 40px;
  & > li {
    font-size: 20px;
    line-height: 40px;
  }
}

.iframe-container__swm-panel {
	display: flex;
	flex-direction: column;
	&__table-container {
		margin-top: 10px;
		margin-left: 60px;
		margin-right: 60px;
	}
}