.markerRow {
	transform-origin: bottom;
	align-items: center;
	justify-items: center;
	display: flex;
	flex-direction: column;
	.markerLabel {
		font-weight: 400;
		font-size: 12px;
		border-radius: 5px;
		padding: 4px;
		background-color: white;
		outline: 2px solid #093664;
	}
}
