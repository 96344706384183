.legend {
	h4 {
		margin: 10px;
	}
	width: 225px;
	background-color: white;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.2);

	.legendList {
		width: 100%;
		.legendItem {
			align-items: center;
			display: flex;
			flex-direction: row;
			width: 100%;
			#redBox {
				background-color: red;
			}
			#blueBox {
				background-color: blue;
			}
			#greyBox {
				background-color: grey;
			}
			#greenBox {
				background-color: lightgreen;
			}
			#hotspotBox {
				background-color: yellow;
				border-radius: 7.5px;
			}
			.colorBox {
				margin: 5px;
				width: 15px;
				height: 15px;
			}
		}
	}
}
