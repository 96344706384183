.navLink {
	text-decoration: none;
	color: white;
}

.navLink:visited {
	color: white;
}

.navbarLogo {
	height: 30px;
	padding-left: 30px;
	padding-right: 30px;
}

.dropDownLink {
	color: black;
}