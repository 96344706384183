.subheadingh5 {
	padding: 15px 0 15px 0;
}

#paragraph {
	text-align: justify;
	text-justify: inter-word;
	line-height: 1.5;
	padding: 0 0 15px 0;
}

.unorderedList {
	font-weight: 300;
}
